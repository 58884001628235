import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const TestimonialEdit = () => {
  const location = useLocation();
  const { TestimonialId } = location.state || {};
  console.log(location, "location.state");

  const [formData, setFormData] = useState({
    t_name: "",
    t_place: "",
    visa: "",
    t_description: "",
    t_image: null,
  });
  const [Details, setDetails] = useState({
    name: "",
    place: "",
    visa: "",
    Description: "",
    image: null,
  });
  const history = useHistory();
  useEffect(() => {
    TestimonialsView();
  }, []);
  //handlers
  const handleInputChange = (e) => {
    console.log(e.target.name, "namessss");

    setDetails({
      ...Details,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setDetails({
      ...Details,
      image: e.target.files[0],
    });
  };
  const TestimonialsView = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/view/testimonial",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: {
        t_id: TestimonialId,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          console.log(data.data.list, "listing");

          setFormData(data.data.list[0]);
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("t_name", Details.name ? Details.name : formData.t_name);
    formdata.append("image", Details.image ? Details.image : formData.t_image);
    formdata.append("t_id", TestimonialId);
    formdata.append("t_place", Details.place ? Details.place : formData.t_place);
    formdata.append(
      "t_description",
      Details.Description ? Details.Description : formData.t_description
    );

    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/edit/testmonials",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          toast.success(data?.data?.message);
          history.push("/services/Testimonials");
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("Error occurred:", error);
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} sm={8} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              Edit Testimonials
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={formData.t_name}
                    name="name"
                    value={Details.name}
                    onChange={handleInputChange}
                    fullWidth
                    // required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label={formData.t_place}
                    name="place"
                    value={Details.place}
                    onChange={handleInputChange}
                    fullWidth
                    // required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={formData.t_description}
                    name="Description"
                    value={Details.Description}
                    onChange={handleInputChange}
                    fullWidth
                    // required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" component="label" fullWidth>
                    Upload Image
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      required
                    />
                  </Button>
                  {formData.t_image && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {Details.image?.name
                        ? Details.image?.name
                        : formData.t_image}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: "15px" }}
                  >
                    Edit Testimonials
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TestimonialEdit;
